<template>
  <v-container>
    <v-row>
      <v-col cols="8">
        <mapa />
      </v-col>
      <v-col cols="4">
        <datos />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import datos from '@/views/crmpro/datos/datos.vue'
import mapa from '@/views/crmpro/datos/mapa.vue'
export default {
  components: {
    datos,
    mapa
  },
}
</script>

<style>

</style>