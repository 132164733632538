<template>
  <v-container>
    <v-card elevation="10">
      <gmap-map
        :center="center"
        :zoom="10"
        style="width: 660px; height: 390px"
      >
        <gmap-marker
          v-for="(gmp, index) in locations"
          :key="index"
          :position="gmp"
          @click="center=gmp"
        />
      </gmap-map>
    </v-card>
  </v-container>
</template>
 
<script>
export default {
  name: "DrawGoogleMap",
  data() {
    return {
      center: {
        lat: 39.7837304,
        lng: -100.4458825
      },
      locations: [],
      currentLocation: null
    };
  },
 
  mounted() {
    this.setLocationLatLng();
  },
 
  methods: {
    setPlace(loc) {
      this.currentLocation = loc;
    },
    setLocationLatLng: function() {
      navigator.geolocation.getCurrentPosition(geolocation => {
        this.center = {
          lat: geolocation.coords.latitude,
          lng: geolocation.coords.longitude
        };
      });
 
      this.locations = [
        {
          lat: 25.780131754963595,
          lng: -100.28109877095389,
          label: 'SAIT MD3'
        },
      ];
 
    }
  }
};
</script>
