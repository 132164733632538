<template>
  <v-container id="datos">
    <v-card>
      <v-toolbar
        height="40"
        dense
        flat
        color="orange"
        dark
      >
        <h3>Datos del cliente</h3>
      </v-toolbar>
      <v-row>
        <v-col class="mx-3">
          <v-text-field
            label="No. Cliente"
            prepend-inner-icon="mdi-account"
            hide-details
          />
          <v-text-field
            label="Nombre comercial"
            prepend-inner-icon="mdi-store"
            hide-details
          />
          <v-text-field
            label="Desisores"
            prepend-inner-icon="mdi-account"
            hide-details
          />
          <v-text-field
            label="Domicilio"
            prepend-inner-icon="mdi-store"
            hide-details
          />
          <v-text-field
            label="Telefono"
            prepend-inner-icon="mdi-phone"
            hide-details
          />
          <v-text-field
            label="Codigo postal"
            prepend-inner-icon="mdi-email"
            hide-details
          />
          <v-text-field
            label="RFC"
            prepend-inner-icon="mdi-circle"
            hide-details
          />
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name:'Datos',
}
</script>

<style>

</style>